import { render, staticRenderFns } from "./modal_documento_saida.vue?vue&type=template&id=ab5b9572&scoped=true"
import script from "./modal_documento_saida.vue?vue&type=script&lang=js"
export * from "./modal_documento_saida.vue?vue&type=script&lang=js"
import style1 from "./modal_documento_saida.vue?vue&type=style&index=1&id=ab5b9572&prod&lang=css"


/* normalize component */
import normalizer from "!../../../node_modules/@vue/cli-service/node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "ab5b9572",
  null
  
)

export default component.exports