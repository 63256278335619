import HTTPService from "@/services/http_service.js";

export default class EntradaService extends HTTPService {
  static build() {
    return new EntradaService();
  }
  resource = "entrada";
  entradaHospital(model) {
    return this.postJson(`pet/entrada-animal`, model);
  }

  entradaHospitalGet(model) {
    return this.getJson(`${this.resource}/hostipal`, model);
  }

  statusEntrada(idEntrada, idStatus) {
    //   1	Aguardando
    //   2	Atendimento
    //   3	Atendido
    //   4	Alta
    //   5	Obito- Descarte
    //   6	Obito-Pesquisa
    return this.postJson(
      `pet/updateStatusEntradaAnimal/${idEntrada}/${idStatus}`,
      {}
    );
  }

  updateMarcacao(model) {
    return this.post(`pet/updatemarcacoes`, model);
  }
}
