<template>
  <v-card>
    <v-toolbar style="background-color: #606060; color: #fff">
      <v-toolbar-title>
        <span class="headline">Emissão de Documento</span>
      </v-toolbar-title>
      <v-spacer></v-spacer>
      <v-btn icon dark @click="$emit('close')">
        <v-icon>mdi-close</v-icon>
      </v-btn>
    </v-toolbar>
    <v-card-text>
      <div class="container">
        <div class="row">
          <div class="col-12">
            <h4>Seleciona o documento que deseja emitir?</h4>
          </div>
        </div>
        <div class="row">
          <div class="col-12">
            <v-select
              v-model="documentoSelect"
              label="Documentos"
              outlined
              :items="listaDocumentos"
              item-text="desc"
              item-value="valor"
              hide-details
            ></v-select>
          </div>
        </div>
      </div>
    </v-card-text>
    <v-card-actions class="d-flex justify-content-between">
      <v-btn color="primary" outlined @click="$emit('close')"> Cancelar </v-btn>
      <v-btn
        color="#606060"
        @click="gerarDoc()"
        :dark="documentoSelect != ''"
        :disabled="documentoSelect == ''"
      >
        Gerar documento
      </v-btn>
    </v-card-actions>
  </v-card>
</template>

<script>
import EntradaService from "@/services/entrada_service.js";
import AnimalService from "@/services/animal_service.js";

import pdfMake from "pdfmake/build/pdfmake";
import pdfFonts from "pdfmake/build/vfs_fonts";
import { TermoAnestesia } from "@/components/documentos/modelos/termo_anestesia.js";
import { AutorizoEutanasia } from "@/components/documentos/modelos/termo_autorizo_eutanasia.js";
import { AutorizoGeral } from "@/components/documentos/modelos/termo_autorizo_geral.js";
import { AutorizoVideo } from "@/components/documentos/modelos/termo_autorizo_video.js";
import { TermoRecusa } from "@/components/documentos/modelos/termo_recusa.js";
pdfMake.vfs = pdfFonts.pdfMake.vfs;

export default {
  mixins: [],
  props: {
    infoAnimal: Object,
  },
  components: {},
  data: () => ({
    services: {
      entradaService: EntradaService.build(),
      animalService: AnimalService.build(),
    },
    listaDocumentos: [
      { valor: "recusa", desc: "Recusa Final" },
      { valor: "aut-geral", desc: "Autorização Geral" },
      { valor: "aut-eutanasia", desc: "Autorização eutanasia" },
      { valor: "aut-video", desc: "Autorização de imagem e video" },
      { valor: "anestesia", desc: "Termo de Resposabilidade para Anestesia" },
    ],
    documentoSelect: "",
    animalInfo: {
      rgv: "-- num rgv --",
      nome: "-- nome animal --",
      classe: "-- classe --",
      especie: "-- especie --",
      sexo: "-- sexo --",
    },
    orgaoInfo: {
      nome: "-- nome orgão --",
      cnpj: "-- cnpj --",
      telefone: "-- telefone --",
      nomeResp: "-- nome Responsavel --",
      cpfResp: "-- cpf --",
      logradouro: "-- logradouro --",
      numero: "-- numero --",
      cidade: "-- cidade --",
      cep: "-- cep --",
    },
    responsavelInfo: {
      nome: "-- nome orgão --",
      cnpj: "-- cnpj --",
      telefone: "-- telefone --",
      nomeResp: "-- nome Responsavel --",
      cpfResp: "-- cpf --",
      logradouro: "-- logradouro --",
      numero: "-- numero --",
      cidade: "-- cidade --",
      cep: "-- cep --",
    },
    unidadeInfo: {
      id: "-- id --",
      razao_social: "-- razao_social --",
      cnpj: "-- cnpj --",
      logradouro: "-- logradouro --",
      numero: "-- numero --",
      cidade: "-- cidade --",
      cep: "-- cep --",
    },
  }),
  watch: {},
  mounted() {
    this.getInfoEntrada();
    const unidade = JSON.parse(sessionStorage.vuex).unidade;
    this.unidadeInfo = {
      id: unidade.id,
      razao_social: unidade.razao_social,
      cnpj: unidade.cnpj,
      logradouro: unidade.logradouro,
      numero: unidade.numero,
      cidade: unidade.cidade,
      cep: unidade.cep,
    };
  },
  computed: {},
  methods: {
    getInfoEntrada() {
      this.services.animalService
        .getEntradaAnimalbyId(this.infoAnimal.id)
        .then(async (response) => {
          if (response.status == 200) {
            const { data } = await response.json();
            const info = data;
            console.log("d", data);
            this.animalInfo = {
              rgv: info.pet_data.rgv,
              nome: info.pet_data.nome,
              classe: info.pet_data.descricao_classe,
              especie: `Popular: ${info.pet_data.nome_popular} | Científico ${info.pet_data.nome_cientifico}`,
              // especie: `Científico: ${info.nome_cientifico} | Popular: ${info.nome_popular}`,
              // cientifico: info.nome_cientifico,
              // popular: info.nome_popular,
              sexo: info.pet_data.sexo,
              idade: info.pet_data.idade,
            };
            this.orgaoInfo = {
              nome: info.orgao_data.razao_social,
              cnpj: info.orgao_data.cnpj,
              telefone: info.orgao_data.telefone,
              nomeResp: info.responsavel_data.nome,
              cpfResp: info.responsavel_data.cpf,
              logradouro: info.responsavel_data.logradouro,
              numero: info.responsavel_data.numero,
              cidade: info.responsavel_data.cidade,
              cep: info.responsavel_data.cep,
            };

            // this.responsavelInfo = {
            //   nome: info.rgv,
            //   cnpj: info.rgv,
            //   telefone: info.rgv,
            //   nomeResp: info.rgv,
            //   cpfResp: info.rgv,
            //   logradouro: info.rgv,
            //   numero: info.rgv,
            //   cidade: info.rgv,
            //   cep: info.rgv,
            // };
            // const model = {
            //   bairro: "Engenheiro Luciano Cavalcante",
            //   cep: "60810-350",
            //   cidade: "Fortaleza",
            //   cnpj: "76.924.668/0001-97",
            //   complemento: "referencia",
            //   data_entrada: "2024-07-12",
            //   data_saida: "1900-01-01",
            //   descricao: "Ave",
            //   estado: "Ceará",
            //   estado_animal: "Ferido",
            //   hora_entrada: "16:28:30",
            //   hora_saida: "00:00:00",
            //   id: "4",
            //   id_classe: "1",
            //   id_endereco: "6",
            //   id_especie: "1",
            //   id_pessoa_juridica: "5",
            //   id_pet: "53",
            //   idade_animal: "filhote",
            //   logradouro: "Avenida Washington Soares",
            //   matricula_servidor: "4588568589",
            //   nome: "Kankinho 10",
            //   nome_cientifico: "testupdate especie",
            //   nome_fantasia: "orgao teste",
            //   nome_popular: "tetste nome_popular",
            //   numero: "71",
            //   referencias: "referencias",
            //   responsavel: "Teste",
            //   rgv: "754486906",
            //   tipo_entrada: "Apreensão",
            // };
          }
        })
        .catch((err) => {
          console.err(err);
        })
        .finally(() => {});
    },
    gerarDoc() {
      const model = this.getModel();
      pdfMake.createPdf(model.docArquivo).download(model.descDoc);
      this.$emit("emitirSaida");
    },
    getModel() {
      switch (this.documentoSelect) {
        case "recusa":
          return {
            docArquivo: TermoRecusa(
              this.animalInfo,
              this.orgaoInfo,
              this.unidadeInfo
            ),
            descDoc: "Recusa Final.pdf",
          };
        case "aut-geral":
          return {
            docArquivo: AutorizoGeral(
              this.animalInfo,
              this.orgaoInfo,
              this.unidadeInfo
            ),
            descDoc: "Autorização Geral.pdf",
          };
        case "aut-eutanasia":
          return {
            docArquivo: AutorizoEutanasia(
              this.animalInfo,
              this.orgaoInfo,
              this.unidadeInfo
            ),
            descDoc: "Autorização eutanasia.pdf",
          };
        case "aut-video":
          return {
            docArquivo: AutorizoVideo(
              this.animalInfo,
              this.orgaoInfo,
              this.unidadeInfo
            ),
            descDoc: "Autorização de imagem e video.pdf",
          };
        case "anestesia":
          return {
            docArquivo: TermoAnestesia(
              this.animalInfo,
              this.orgaoInfo,
              this.unidadeInfo
            ),
            descDoc: "Termo de Resposabilidade para Anestesia.pdf",
          };

        default:
          return false;
      }
    },
  },
};
</script>
