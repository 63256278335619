<template>
  <div>
    <div class="navbar-custom">
      <div class="d-flex justify-content-between align-items-center">
        <div class="d-flex justify-content-between align-items-center">
          <v-app-bar-nav-icon @click="menuRetratil"></v-app-bar-nav-icon>
          <v-fade-transition>
            <v-img
              src="@/assets/images/logo_spmv_branca.png"
              width="130px"
              contain
              class="ml-5"
              v-if="unidadeLogada.id == 29"
            ></v-img>
            <v-img
              src="@/assets/images/logo_televet_branca.png"
              width="110px"
              class="ml-5"
              v-else-if="unidadeLogada.id == 24"
            ></v-img>
            <v-img
              v-else
              src="@/assets/images/Logo-branca.png"
              class="ml-5"
            ></v-img>
          </v-fade-transition>
        </div>
        <v-menu offset-y transition="scale-transition" origin="center center">
          <template v-slot:activator="{ on, attrs }">
            <v-btn
              v-bind="attrs"
              v-on="on"
              style="background-color: transparent !important; height: auto"
              class=""
              elevation="0"
            >
              <div
                class="d-flex"
                v-if="DADOS_CADASTRAIS_MIXIN_srcFotoUsuario !== ''"
              >
                <v-list-item-avatar>
                  <img
                    class="mr-1 round"
                    :src="DADOS_CADASTRAIS_MIXIN_srcFotoUsuario"
                    alt=""
                  />
                </v-list-item-avatar>
                <v-list-item-title>
                  <p class="mb-0 color-white">{{ saudacoes }}</p>
                  <p class="color-white">{{ unidadeLogada.nome }}</p>
                  <i></i>
                </v-list-item-title>
              </div>
              <v-progress-circular
                v-else
                indeterminate
                color="green"
              ></v-progress-circular>
            </v-btn>
          </template>
          <ul class="menu-perfil text-left mb-0">
            <li>
              <a @click="Dados_cadastrais">Dados cadastrais</a>
            </li>
            <li>
              <v-btn @click="logout">Sair</v-btn>
            </li>
          </ul>
        </v-menu>
      </div>
    </div>
  </div>
</template>

<script>
import { global } from "@/config/constants";
import axios from "axios";
import VuexUsuarioMixin from "@/mixins/vuex_usuario_mixin.js";
import VuexTokenMixin from "@/mixins/vuex_token_mixin.js";
import DadosCadastraisMixin from "@/mixins/dados_cadastrais_mixin.js";
import AuthService from "@/services/auth_service";
import ChamefacilMixin from "@/mixins/chamefacil.js";

export default {
  mixins: [
    VuexUsuarioMixin,
    VuexTokenMixin,
    DadosCadastraisMixin,
    ChamefacilMixin,
  ],
  data: () => ({
    hidden: false,
    tokenChamefacil: "",
    unidadeLogada: {},
    services: {
      authService: AuthService.build(),
    },
  }),

  computed: {
    primeiroNomeUsuario() {
      return this.$_GETTERS_usuario.dados
        ? this.$_GETTERS_usuario.dados.nome.trim().split(" ")[0]
        : "";
    },
    saudacoes() {
      if (this.primeiroNomeUsuario) {
        return `Olá, ${this.primeiroNomeUsuario}${
          this.$_GETTER_perfil.descricao == "PACIENTE"
            ? ", " + this.$_GETTER_perfil.descricao
            : ""
        }`;
      }
      return "";
    },

    perfilDescricao() {
      return this.$_GETTER_perfil.descricao
        ? this.$_GETTER_perfil.descricao
        : "";
    },
  },
  beforeMount() {
    // this.primeiroNomeUsuario();
    // this.saudacoes();
    this.setUnidadeLogada();
  },
  methods: {
    Dados_cadastrais() {
      this.$router.replace("dadoscadastrais");
    },
    Trocar_senha() {
      this.$router.replace("trocarsenha");
    },
    async logout() {
      this.tokenChamefacil = localStorage.getItem("tokenChamefacil") || "";

      try {
        await this.getservidorChameFacil();
      } catch (error) {
        console.log(error);
      }

      if (!this.tokenChamefacil) {
        try {
          await this.getChamefacilToken();
        } catch (error) {
          console.log(error);
        }
      }

      this.$_ACTIONS_setUsuario({});
      this.$_ACTIONS_setUnidade({});
      this.$_ACTIONS_setToken("");
      this.$router.replace("login");

      localStorage.removeItem("idFilaAlocada");
      localStorage.removeItem("dadosUsuario");
      localStorage.removeItem("idRequisicaoProcedimento");
      localStorage.removeItem("localAtendimento");
      localStorage.removeItem("especialidadeAtendimento");
      localStorage.clear();
      const headers = { token: this.tokenChamefacil };
      let senhaPresa = JSON.parse(localStorage.getItem("infoPaciente"));
      if (senhaPresa) {
        axios
          .get(
            global.api.baseUrlChamefacil_api +
              "/atendimento/aguardarinterna?chamadainternaId=" +
              senhaPresa.IdChamadaInterna,
            { headers }
          )
          .then((resp) => {
            console.log(resp.data);
            localStorage.removeItem("infoPaciente");
          });
      } else {
        localStorage.removeItem("infoPaciente");
      }
      axios
        .get(global.api.baseUrlChamefacil + "/acesso/sair", { headers })
        .then((resp) => {
          console.log(resp);
          localStorage.removeItem("tokenChamefacil");
        });
    },
    menuRetratil() {
      this.hidden = !this.hidden;
      this.$emit("retratil");
    },
    setUnidadeLogada() {
      setTimeout(() => {
        if (this.$_GETTER_perfil.id == 6) {
          this.unidadeLogada = {
            nome: JSON.parse(localStorage.getItem("unidades_prefeitura"))[0]
              .cidade,
          };
        } else {
          this.unidadeLogada = JSON.parse(sessionStorage.vuex).unidade;
        }
      }, 1000);
      // return this.$_GETTER_Unidade;
    },
    async getservidorChameFacil() {
      const unidadeId = JSON.parse(sessionStorage.getItem("vuex")).unidade.id;

      await this.services.authService
        .servidorChameFacil(unidadeId)
        .then(async (response) => {
          const resp = await response.json();

          return {
            status: response.status,
            resp,
          };
        })
        .then(({ status, resp }) => {
          if (status == 200) {
            this.setServidorChamefacil(resp.data.host, resp.data.api);
          }
        });
    },
    async getChamefacilToken() {
      const unidadeId = JSON.parse(sessionStorage.getItem("vuex")).unidade.id;

      await this.services.authService
        .tokenChamefacil(unidadeId)
        .then(async (response) => {
          const resp = await response.json();
          return {
            status: response.status,
            resp,
          };
        })
        .then(({ status, resp }) => {
          if (status == 200) {
            console.log(resp);
            localStorage.setItem("tokenChamefacil", resp.data.Token);
            this.tokenChamefacil = resp.data.Token;
          }
        });
    },
  },
};
</script>

<style scoped>
.navbar-custom {
  /* padding: 20px 40px; */
  background-color: #1daf80;
  -webkit-box-shadow: 0 0 45px 0 rgb(0 0 0 / 12%);
  box-shadow: 0 0 45px 0 rgb(0 0 0 / 12%);
  left: 0;
  right: 0;
  top: 0;
  z-index: 100;
  position: fixed;
}

.menu-perfil {
  list-style: none;
  padding: inherit;
  background-color: #fff;
}

.menu-perfil li {
  padding: 0.5rem 0 0.5rem 1rem;
}

.menu-perfil li a {
  font-size: 1rem;
}

div {
  font-size: 12px;
}

.theme--light.v-system-bar .v-icon {
  color: #fff !important;
  padding: 10px;
  margin: auto;
}
.color-white {
  color: white;
  font-weight: bold;
}
</style>
